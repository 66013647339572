import React from 'react';
import AccordionWithLink from '../AccordionWithLink';
import { Layout, Text } from '@fluentui/react-northstar';

const Permissions = () => {
    return (
        <AccordionWithLink
            panels={[
                {
                    title: 'Microsoft administrator permissions',
                    content: (
                        <Layout
                            className="content-wrapper"
                            key="admin-permissions"
                            style={{ width: '635px' }}
                        >
                            <Layout className="ui-text-wrapper">
                                <Layout className="ui-text-wrapper-item">
                                    <Text
                                        weight="bold"
                                        content="What are Microsoft administrator permissions?"
                                    />
                                </Layout>

                                <Layout className="ui-text-wrapper-item">
                                    <Text
                                        weight="regular"
                                        content="Microsoft 365 or Office 365 subscription comes with a set of administrator roles that you can assign to users in your organisation in the Microsoft 365 admin center."
                                    />
                                </Layout>

                                <Layout className="ui-text-wrapper-item">
                                    <Text
                                        weight="regular"
                                        content="Each admin role maps to common business functions and gives people in your organization permissions to do specific tasks in the admin centers."
                                    />
                                </Layout>
                            </Layout>

                            <Layout className="ui-text-wrapper">
                                <Layout className="ui-text-wrapper-item">
                                    <Text
                                        weight="bold"
                                        content="How to check if you have administrator permissions"
                                    />
                                </Layout>

                                <Layout className="ui-text-wrapper-item">
                                    <Text
                                        weight="regular"
                                        content="Sign into Microsoft 365 and select the app launcher, if you see an ‘Admin’ button that means that you’re an administrator."
                                    />
                                </Layout>

                                <Layout className="ui-text-wrapper-item">
                                    <Text
                                        weight="regular"
                                        content="If you don’t see the ‘Admin’ button here, this means that you do not have administrator permissions. "
                                    />
                                </Layout>
                            </Layout>

                            <Layout className="ui-text-wrapper">
                                <Layout className="ui-text-wrapper-item">
                                    <Text
                                        weight="bold"
                                        content="How to get administrator permissions"
                                    />
                                </Layout>

                                <Layout className="ui-text-wrapper-item">
                                    <Text
                                        weight="regular"
                                        content="You will need to contact an administrator within your organisation and ask them to make you a company admin before you can download and use Gamma Voice. "
                                    />
                                </Layout>

                                <Layout className="ui-text-wrapper-item">
                                    <Text weight="regular">
                                        <span>To read more about administrator roles, read</span>{' '}
                                        <a href="https://docs.microsoft.com/en-us/microsoft-365/admin/add-users/about-admin-roles?view=o365-worldwide">
                                            Microsoft’s guide on admin roles
                                        </a>
                                    </Text>
                                </Layout>
                            </Layout>
                        </Layout>
                    ),
                },
            ]}
        />
    );
};

export default Permissions;
