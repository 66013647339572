import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Flex, Text, Layout } from '@fluentui/react-northstar';
import helpStyles from './helpStyles';
import CircleIcon from './icons/Circle';

import messages from './messages';

const Plans = () => (
    <Layout className="text-format">
        <Flex column={true} style={helpStyles.textItemColumn}>
            <Text style={helpStyles.textTitle}>
                <FormattedMessage {...messages.msPlansItem1Title} />
            </Text>
            <Flex vAlign="center" style={helpStyles.circleAndText}>
                <Text>
                    <FormattedMessage {...messages.msPlansItem1list1} />
                </Text>
            </Flex>
            <Flex vAlign="center" style={helpStyles.circleAndText}>
                <Text weight="bold">
                    <FormattedMessage {...messages.msPlansItem1list3} />
                </Text>
            </Flex>
            <Flex vAlign="center" style={helpStyles.circleAndText}>
                <Text>
                    <FormattedMessage {...messages.msPlansItem1list2} />
                </Text>
            </Flex>
        </Flex>

        <Flex column={false}>
            <Flex column={true} style={helpStyles.textItemColumnBorderR}>
                <Text style={helpStyles.textTitle}>
                    <FormattedMessage {...messages.msPlansExtraLicensesTitle} />
                    <b>
                        <FormattedMessage {...messages.msPlansExtraLicensesTitle2} />
                    </b>
                    <FormattedMessage {...messages.msPlansExtraLicensesTitle3} />
                </Text>
                <Flex vAlign="center" style={helpStyles.circleAndText}>
                    <CircleIcon />
                    <Text style={helpStyles.textItem}>
                        <FormattedMessage {...messages.msPlansExtraLicensesItem1} />
                    </Text>
                </Flex>
                <Flex vAlign="center" style={helpStyles.circleAndText}>
                    <CircleIcon />
                    <Text style={helpStyles.textItem}>
                        <FormattedMessage {...messages.msPlansExtraLicensesItem2} />
                    </Text>
                </Flex>
                <Flex vAlign="center" style={helpStyles.circleAndText}>
                    <CircleIcon />
                    <Text style={helpStyles.textItem}>
                        <FormattedMessage {...messages.msPlansExtraLicensesItem3} />
                    </Text>
                </Flex>
                <Flex vAlign="center" style={helpStyles.circleAndText}>
                    <CircleIcon />
                    <Text style={helpStyles.textItem}>
                        <FormattedMessage {...messages.msPlansExtraLicensesItem4} />
                    </Text>
                </Flex>
                <Flex vAlign="center" style={helpStyles.circleAndText}>
                    <CircleIcon />
                    <Text style={helpStyles.textItem}>
                        <FormattedMessage {...messages.msPlansExtraLicensesItem5} />
                    </Text>
                </Flex>
                <Flex vAlign="center" style={helpStyles.circleAndText}>
                    <CircleIcon />
                    <Text style={helpStyles.textItem}>
                        <FormattedMessage {...messages.msPlansExtraLicensesItem6} />
                    </Text>
                </Flex>
                <Flex vAlign="center" style={helpStyles.circleAndText}>
                    <CircleIcon />
                    <Text style={helpStyles.textItem}>
                        <FormattedMessage {...messages.msPlansExtraLicensesItem7} />
                    </Text>
                </Flex>
                <Flex vAlign="center" style={helpStyles.circleAndText}>
                    <CircleIcon />
                    <Text style={helpStyles.textItem}>
                        <FormattedMessage {...messages.msPlansExtraLicensesItem8} />
                    </Text>
                </Flex>
                <Flex vAlign="center" style={helpStyles.circleAndText}>
                    <CircleIcon />
                    <Text style={helpStyles.textItem}>
                        <FormattedMessage {...messages.msPlansExtraLicensesItem9} />
                    </Text>
                </Flex>
                <Flex vAlign="center" style={helpStyles.circleAndText}>
                    <CircleIcon />
                    <Text style={helpStyles.textItem}>
                        <FormattedMessage {...messages.msPlansExtraLicensesItem10} />
                    </Text>
                </Flex>
                <Flex vAlign="center" style={helpStyles.circleAndText}>
                    <CircleIcon />
                    <Text style={helpStyles.textItem}>
                        <FormattedMessage {...messages.msPlansExtraLicensesItem11} />
                    </Text>
                </Flex>
            </Flex>

            <Flex column={true} style={helpStyles.textItemColumn}>
                <Text style={helpStyles.textTitle}>
                    <FormattedMessage {...messages.msPlansIncludeLicensesTitle} />
                    <b>
                        <FormattedMessage {...messages.msPlansIncludeLicensesTitle2} />
                    </b>
                    <FormattedMessage {...messages.msPlansIncludeLicensesTitle3} />
                </Text>
                <Flex vAlign="center" style={helpStyles.circleAndText}>
                    <CircleIcon />
                    <Text style={helpStyles.textItem}>
                        <FormattedMessage {...messages.msPlansIncludeLicensesItem1} />
                    </Text>
                </Flex>
                <Flex vAlign="center" style={helpStyles.circleAndText}>
                    <CircleIcon />
                    <Text style={helpStyles.textItem}>
                        <FormattedMessage {...messages.msPlansIncludeLicensesItem2} />
                    </Text>
                </Flex>
                <Flex vAlign="center" style={helpStyles.circleAndText}>
                    <CircleIcon />
                    <Text style={helpStyles.textItem}>
                        <FormattedMessage {...messages.msPlansIncludeLicensesItem3} />
                    </Text>
                </Flex>
                <Flex vAlign="center" style={helpStyles.circleAndText}>
                    <CircleIcon />
                    <Text style={helpStyles.textItem}>
                        <FormattedMessage {...messages.msPlansIncludeLicensesItem4} />
                    </Text>
                </Flex>
                <Flex vAlign="center" style={helpStyles.circleAndText}>
                    <CircleIcon />
                    <Text style={helpStyles.textItem}>
                        <FormattedMessage {...messages.msPlansIncludeLicensesItem5} />
                    </Text>
                </Flex>
                <Flex vAlign="center" style={helpStyles.circleAndText}>
                    <CircleIcon />
                    <Text style={helpStyles.textItem}>
                        <FormattedMessage {...messages.msPlansIncludeLicensesItem6} />
                    </Text>
                </Flex>
            </Flex>
        </Flex>
    </Layout>
);

const MicrosoftPlans = () => {
    return (
        <Flex style={{ padding: 0, maxWidth: 672 }} column={true}>
            <Plans />
        </Flex>
    );
};

export default MicrosoftPlans;
