import AccordionWithLink from '../AccordionWithLink';
import { Alert, ExclamationTriangleIcon, Layout, Text } from '@fluentui/react-northstar';
import React from 'react';

const PowerShellModule = () => {
    return (
        <AccordionWithLink
            panels={[
                {
                    title: 'Microsoft Teams PowerShell module  - certain versions only',
                    content: (
                        <Layout
                            className="content-wrapper"
                            key="ps-module"
                            style={{ width: '635px' }}
                        >
                            <Layout className="ui-text-wrapper">
                                <Layout className="ui-text-wrapper-item">
                                    <Text
                                        weight="bold"
                                        content="What is Microsoft Teams PowerShell module?"
                                    />
                                </Layout>

                                <Layout className="ui-text-wrapper-item">
                                    <Text
                                        weight="regular"
                                        content="Microsoft Teams PowerShell module is a set of cmdlets for managing Teams directly from the PowerShell command line. "
                                    />
                                </Layout>

                                <Layout className="ui-text-wrapper-item">
                                    <Text
                                        weight="regular"
                                        content="PowerShell provides powerful features for automation that can be leveraged for managing your Teams workload."
                                    />
                                </Layout>
                            </Layout>

                            <Layout className="ui-text-wrapper">
                                <Layout className="ui-text-wrapper-item">
                                    <Text
                                        weight="bold"
                                        content="Accepted PowerShell module versions"
                                    />
                                </Layout>

                                <Layout className="ui-text-wrapper-item">
                                    <Text
                                        weight="regular"
                                        content="The PowerShell module version that you need will be different depending on what product you have."
                                    />
                                </Layout>
                            </Layout>

                            <Layout className="ui-text-wrapper">
                                <Layout className="ui-text-wrapper-item">
                                    <Text weight="bold" content="Horizon products can use:" />
                                </Layout>

                                <Layout className="ui-text-wrapper-item">
                                    <ul>
                                        <li>PowerShell module version 2.5.1</li>
                                        <li>PowerShell module version 2.6.0</li>
                                    </ul>
                                </Layout>
                            </Layout>

                            <Layout className="ui-text-wrapper">
                                <Layout className="ui-text-wrapper-item">
                                    <Text
                                        weight="bold"
                                        content="Microsoft Direct Routing products can use:"
                                    />
                                </Layout>

                                <Layout className="ui-text-wrapper-item">
                                    <ul>
                                        <li>PowerShell module version 2.3.1</li>
                                    </ul>
                                </Layout>

                                <Alert
                                    warning
                                    icon={
                                        <ExclamationTriangleIcon
                                            outline
                                            style={{
                                                width: 16,
                                                height: 16,
                                                position: 'absolute',
                                                left: 30,
                                                top: 20,
                                            }}
                                        />
                                    }
                                    content="If you have any other version of PowerShell installed on your computer, you need to uninstall this and install an accepted version."
                                    style={{
                                        width: 500,
                                        height: 74,
                                        lineHeight: '21px',
                                        fontSize: 14,
                                        padding: '16px 13px 16px 40px',
                                        marginBottom: 24,
                                        background: '#FBF6D9',
                                        border: 0,
                                    }}
                                />
                            </Layout>

                            <Layout className="ui-text-wrapper">
                                <Layout className="ui-text-wrapper-item">
                                    <Text
                                        weight="bold"
                                        content="How to check what PowerShell module you have"
                                    />
                                </Layout>

                                <Layout className="ui-text-wrapper-item">
                                    <Text
                                        weight="regular"
                                        content="Run the following cmdlet in PowerShellGet:"
                                    />
                                </Layout>

                                <Layout className="ui-text-wrapper-item">
                                    <Text
                                        weight="regular"
                                        style={{
                                            fontFamily: 'IBM Plex Mono',
                                            background: '#ECEDF8',
                                            width: 183,
                                            height: 24,
                                            fontSize: '16px',
                                            lineHeight: '24px',
                                            color: '#1B1F3B',
                                        }}
                                    >
                                        Get-InstalledModule
                                    </Text>
                                </Layout>

                                <Layout className="ui-text-wrapper-item">
                                    <Text
                                        weight="regular"
                                        content="This will produce all of the modules you have installed and their versions. "
                                    />
                                </Layout>

                                <Layout className="ui-text-wrapper-item">
                                    <Text weight="regular">
                                        <span>For more help with PowerShell, visit </span>
                                        <a href="https://docs.microsoft.com/en-us/powershell/module/powershellget/uninstall-module?view=powershell-7.1">
                                            Microsoft’s guide on PowerShell module
                                        </a>
                                    </Text>
                                </Layout>
                            </Layout>

                            <Layout className="ui-text-wrapper">
                                <Layout className="ui-text-wrapper-item">
                                    <Text
                                        weight="bold"
                                        content="How to uninstall PowerShell module"
                                    />
                                </Layout>

                                <Layout className="ui-text-wrapper-item">
                                    <Text weight="regular">
                                        <span>To uninstall PowerShell module, read </span>
                                        <a href="https://docs.microsoft.com/en-us/powershell/module/powershellget/uninstall-module?view=powershell-7.1">
                                            Microsoft’s guide to uninstall PowerShell module
                                        </a>
                                    </Text>
                                </Layout>
                            </Layout>

                            <Layout className="ui-text-wrapper">
                                <Layout className="ui-text-wrapper-item">
                                    <Text weight="bold" content="Installing PowerShell module" />
                                </Layout>

                                <Layout className="ui-text-wrapper-item">
                                    <Text
                                        weight="regular"
                                        content="The links below will take you to the download page for each PowerShell module version:"
                                    />
                                </Layout>

                                <Layout className="ui-text-wrapper-item">
                                    <Text weight="regular">
                                        <a href="https://www.powershellgallery.com/packages/MicrosoftTeams/2.3.1">
                                            Install PowerShell module version 2.3.1{' '}
                                        </a>
                                    </Text>
                                </Layout>

                                <Layout className="ui-text-wrapper-item">
                                    <Text weight="regular">
                                        <a href="https://www.powershellgallery.com/packages/MicrosoftTeams/2.5.1">
                                            Install PowerShell module version 2.5.1
                                        </a>
                                    </Text>
                                </Layout>

                                <Layout className="ui-text-wrapper-item">
                                    <Text weight="regular">
                                        <a href="https://www.powershellgallery.com/packages/MicrosoftTeams/2.6.0">
                                            Install PowerShell module version 2.6.0
                                        </a>
                                    </Text>
                                </Layout>
                            </Layout>
                        </Layout>
                    ),
                },
            ]}
        />
    );
};

export default PowerShellModule;
