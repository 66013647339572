import { Alert, ExclamationTriangleIcon, Layout, Text } from '@fluentui/react-northstar';
import AccordionWithLink from '../AccordionWithLink';
import React from 'react';

const PowerShellInfo = () => {
    return (
        <AccordionWithLink
            panels={[
                {
                    title: 'Microsoft Teams PowerShell - certain versions only',
                    content: (
                        <Layout
                            className="content-wrapper"
                            key="ps-info"
                            style={{ width: '635px' }}
                        >
                            <Layout className="ui-text-wrapper">
                                <Layout className="ui-text-wrapper-item">
                                    <Text weight="bold" content="What is Microsoft PowerShell?" />
                                </Layout>

                                <Layout className="ui-text-wrapper-item">
                                    <Text
                                        weight="regular"
                                        content="PowerShell is a cross-platform task automation solution made up of a command-line shell, a scripting language, and a configuration management framework."
                                    />
                                </Layout>
                            </Layout>

                            <Layout className="ui-text-wrapper">
                                <Layout className="ui-text-wrapper-item">
                                    <Text weight="bold" content="Accepted PowerShell versions:" />
                                </Layout>

                                <Layout className="ui-text-wrapper-item">
                                    <ul>
                                        <li>PowerShell version 5.1</li>
                                        <li>PowerShell version 7.1 (PowerShell core)</li>
                                    </ul>
                                </Layout>

                                <Alert
                                    warning
                                    icon={
                                        <ExclamationTriangleIcon
                                            outline
                                            style={{
                                                width: 16,
                                                height: 16,
                                                position: 'absolute',
                                                left: 30,
                                                top: 20,
                                            }}
                                        />
                                    }
                                    content="If you have any other version of PowerShell installed on your computer, you need to uninstall this and install an accepted version."
                                    style={{
                                        width: 500,
                                        height: 74,
                                        lineHeight: '21px',
                                        fontSize: 14,
                                        padding: '16px 13px 16px 40px',
                                        marginBottom: 24,
                                        background: '#FBF6D9',
                                        border: 0,
                                    }}
                                />
                            </Layout>

                            <Layout className="ui-text-wrapper">
                                <Layout className="ui-text-wrapper-item">
                                    <Text
                                        weight="bold"
                                        content="How to check which PowerShell version you have"
                                    />
                                </Layout>

                                <Layout className="ui-text-wrapper-item">
                                    <Text
                                        weight="regular"
                                        content="Open the “Start” menu on your computer, search for “Windows PowerShell,” and click it in the search results."
                                    />
                                </Layout>

                                <Layout className="ui-text-wrapper-item">
                                    <Text
                                        weight="regular"
                                        content="Once you have the PowerShell window open, type in the below command and press Enter:"
                                    />
                                </Layout>

                                <Layout className="ui-text-wrapper-item">
                                    <Text
                                        weight="regular"
                                        style={{
                                            fontFamily: 'IBM Plex Mono',
                                            background: '#ECEDF8',
                                            width: 144,
                                            height: 24,
                                            fontSize: '16px',
                                            lineHeight: '24px',
                                            color: '#1B1F3B',
                                        }}
                                    >
                                        $PSVersionTable
                                    </Text>
                                </Layout>

                                <Layout className="ui-text-wrapper-item">
                                    <Text
                                        weight="regular"
                                        content="This will give you information about your PowerShell. Under “name” you will see “PSVersion” - this is your PowerShell version. "
                                    />
                                </Layout>

                                <Layout className="ui-text-wrapper-item">
                                    <Text
                                        weight="regular"
                                        content="Gamma Voice will only work with version 5.1. If you have a higher version installed, you will need to uninstall this and install version 5.1."
                                    />
                                </Layout>
                            </Layout>

                            <Layout className="ui-text-wrapper">
                                <Layout className="ui-text-wrapper-item">
                                    <Text weight="bold" content="How to uninstall PowerShell" />
                                </Layout>

                                <Layout className="ui-text-wrapper-item">
                                    <Text
                                        weight="regular"
                                        content="Open the “Start” menu on your computer, search for “Windows PowerShell”, and click it in the search results. "
                                    />
                                </Layout>

                                <Layout className="ui-text-wrapper-item">
                                    <Text
                                        weight="regular"
                                        content="Right-click Windows PowerShell app, and select Uninstall, or expand the menu in the right to select Uninstall to remove Windows PowerShell."
                                    />
                                </Layout>

                                <Layout className="ui-text-wrapper-item">
                                    <Text
                                        weight="regular"
                                        content="You will now need to install PowerShell version 5.1."
                                    />
                                </Layout>
                            </Layout>

                            <Layout className="ui-text-wrapper">
                                <Layout className="ui-text-wrapper-item">
                                    <Text weight="bold" content="Installing PowerShell" />
                                </Layout>

                                <Layout className="ui-text-wrapper-item">
                                    <Text
                                        weight="regular"
                                        content="The links below will take you to the download page for each PowerShell version:"
                                    />
                                </Layout>

                                <Layout className="ui-text-wrapper-item">
                                    <Text weight="regular">
                                        <a href="https://docs.microsoft.com/en-us/skypeforbusiness/set-up-your-computer-for-windows-powershell/download-and-install-windows-powershell-5-1">
                                            Install PowerShell version 5.1
                                        </a>
                                    </Text>
                                </Layout>

                                <Layout className="ui-text-wrapper-item">
                                    <Text weight="regular">
                                        <a href="https://docs.microsoft.com/en-us/powershell/scripting/install/installing-powershell-on-windows?view=powershell-7.1">
                                            Install PowerShell version 7.1
                                        </a>
                                    </Text>
                                </Layout>
                            </Layout>
                        </Layout>
                    ),
                },
            ]}
        />
    );
};

export default PowerShellInfo;
