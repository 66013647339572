import { defineMessages } from 'react-intl';

const messages = defineMessages({
    title: {
        id: 'help.title',
        defaultMessage: 'Help centre',
    },
    description: {
        id: 'help.description',
        defaultMessage:
            'Find help content and videos on how to configure Gamma Voice app and how to assign your users. ',
    },
    list1: {
        id: 'help.menu.list1',
        defaultMessage: 'Configuration',
    },
    list2: {
        id: 'help.menu.list2',
        defaultMessage: 'Manage users',
    },
    list3: {
        id: 'help.menu.list3',
        defaultMessage: 'Microsoft Plans',
    },
    configHeader1: {
        id: 'help.config.header1',
        defaultMessage: 'Get started',
    },
    configHeader2: {
        id: 'help.config.header2',
        defaultMessage: 'Permissions',
    },
    configHeader3: {
        id: 'help.config.header3',
        defaultMessage: 'Company details',
    },
    configHeader4: {
        id: 'help.config.header4',
        defaultMessage: 'Setting up Gamma Voice',
    },
    configHeader5: {
        id: 'help.config.header5',
        defaultMessage: 'Help tutorial',
    },
    configItem1Title: {
        id: 'help.config.itemTitle',
        defaultMessage: 'Before you get started with the app, you will need to:',
    },
    configItem1list1: {
        id: 'help.config.item1.list1',
        defaultMessage: 'be a Microsoft administrator',
    },
    configItem1list2: {
        id: 'help.config.item1.list2',
        defaultMessage: 'have 2 Microsoft Phone System licenses available.',
    },
    configItem2list1: {
        id: 'help.config.item2.list1',
        defaultMessage: 'You’ll be asked to accept two sets of permissions.',
    },
    configItem2list2: {
        id: 'help.config.item2.list2',
        defaultMessage:
            'The first set is to allow the app access to read your profile. The second set is to allow the app access to configure your Microsoft voice service.',
    },
    configItem3list1: {
        id: 'help.config.item3.list1',
        defaultMessage:
            'Before you can start setting up Gamma Voice for your Microsoft organisation, you will need to confirm whether the company that is displayed here is correct.  ',
    },
    configItem3list2: {
        id: 'help.config.item3.list2',
        defaultMessage:
            'If you don’t recognise the company displayed here, you will need to get in touch with the partner who is supplying your direct routing service and ask them to update your details. ',
    },
    configItem3list3: {
        id: 'help.config.item3.list3',
        defaultMessage:
            'Once they have done this successfully the page will automatically be refreshed, and you should see the correct company details here.  ',
    },
    configItem4list1: {
        id: 'help.config.item4.list1',
        defaultMessage: 'During this part of the configuration, the app is: ',
    },
    configItem4list2: {
        id: 'help.config.item4.list2',
        defaultMessage: 'registering domains with your Microsoft 365 account ',
    },
    configItem4list3: {
        id: 'help.config.item4.list3',
        defaultMessage: 'configuring voice routing policies, PSTN usage records and voice routes',
    },
    configItem4list4: {
        id: 'help.config.item4.list4',
        defaultMessage:
            'Registering the domains usually takes no longer than 10 minutes but can sometimes take longer.',
    },
    configItem4list5: {
        id: 'help.config.item4.list5',
        defaultMessage:
            'If it hasn’t been completed in 10 minutes, you can leave the app and it will continue to register the domains in the background.  ',
    },
    configItem4list6: {
        id: 'help.config.item4.list6',
        defaultMessage:
            'We will send you an email once it has finished and you can continue with the configuration.',
    },
    manageUsersItem1list1: {
        id: 'manage.item1.list1',
        defaultMessage: 'Assigning users',
    },
    manageUsersItem1list2: {
        id: 'manage.item1.list2',
        defaultMessage: 'You can link your Microsoft users to your voice product in two ways:',
    },
    manageUsersItem1list4: {
        id: 'manage.item1.list4',
        defaultMessage: 'using the bulk upload users tool.  ',
    },
    manageUsersItem1list3: {
        id: 'manage.item1.list3',
        defaultMessage:
            'using the dropdown on the table (you will need to start typing to populate a user or number from the drop down) ',
    },
    manageUsersItem1list5: {
        id: 'manage.item1.list5',
        defaultMessage:
            'When you’ve selected a user from the drop down, a save users button will appear. By clicking save you will assign the selected user / number and they will be able to make and receive calls.',
    },
    manageUsersItem1list6: {
        id: 'manage.item1.list6',
        defaultMessage:
            'To make changes to the users / number that you have already assigned, click the three dots on the right-hand side of the table. ',
    },
    manageUsersItem2list1: {
        id: 'manage.item2.list1',
        defaultMessage: 'Bulk upload users',
    },
    manageUsersItem2list2: {
        id: 'manage.item2.list2',
        defaultMessage:
            'The bulk upload tool allows you to upload a CSV file containing all the Microsoft users that you’d like to link to your voice product. ',
    },
    manageUsersItem2list3: {
        id: 'manage.item2.list3',
        defaultMessage:
            'Before using the tool you can export the details from the manage users section by clicking the “export” button. It will look like one of the examples below depending on what voice product you have.',
    },
    manageUsersHorizonVoiceProduct: {
        id: 'manage.horizon.voice.product',
        defaultMessage: 'Horizon voice product',
    },
    manageUsersSipVoiceProductTitle: {
        id: 'manage.sip.voice.product.title',
        defaultMessage: 'SIP voice product',
    },
    manageUsersReccomendationCSV: {
        id: 'manage.sip.voice.reccomendation.csv',
        defaultMessage:
            'We recommend using the data import tool to load the CSV data when working in Excel. You can do this by clicking Data > Get data > From file > From text/CSV and selecting the exported file.',
    },
    manageUsersSipVoiceProductItem1: {
        id: 'manage.sip.voice.product.item1',
        defaultMessage:
            'You will need to update column B with either a Horizon username or a phone number, depending on which voice product you have. ',
    },
    manageUsersSipVoiceProductItem2: {
        id: 'manage.sip.voice.product.item2',
        defaultMessage: 'In the “action” column, you can enter 3 actions:',
    },
    manageUsersHorizonExampleTitle: {
        id: 'manage.sip.voice.horizon.title',
        defaultMessage: 'Horizon example',
    },
    manageUsersSipExampleTitle: {
        id: 'manage.sip.voice.sip.title',
        defaultMessage: 'SIP example',
    },
    manageUsersSipVoiceProductItem3List1: {
        id: 'manage.sip.voice.product.item3.list1',
        defaultMessage:
            'Assign - this will assign any details you have entered to the selected Microsoft email.',
    },
    manageUsersSipVoiceProductItem3List2: {
        id: 'manage.sip.voice.product.item3.list2',
        defaultMessage:
            'Unassign - this will unassign any details that you have previously assigned using with the bulk upload user tool, or by manually assigning details from the manage users section. ',
    },
    manageUsersSipVoiceProductItem3List3: {
        id: 'manage.sip.voice.product.item3.list3',
        defaultMessage:
            'Preserve - this will keep any details the same. For example, in the below image you would enter preserve if you didn’t want to make any changes to joebloggs@example.com.',
    },
    manageUsersSipVoiceProductWarning: {
        id: 'manage.sip.voice.product.item3.warning',
        defaultMessage: 'Numbers must be entered in +44 format',
    },
    manageUsersSipVoiceProductItem4: {
        id: 'manage.sip.voice.product.item4',
        defaultMessage:
            "If you have assigned a user before and you'd like to change their details: ",
    },
    manageUsersSipVoiceProductItem4List1: {
        id: 'manage.sip.voice.product.item4.list1',
        defaultMessage: 'Enter unassign next to the correct user and upload the CSV.',
    },
    manageUsersSipVoiceProductItem4List2: {
        id: 'manage.sip.voice.product.item4.list2',
        defaultMessage:
            'Update the details on the CSV file, change unassign to assign and upload the document.',
    },
    manageUsersItem3list1: {
        id: 'manage.item3.list1',
        defaultMessage: 'Errors',
    },
    manageUsersItem3list2: {
        id: 'manage.item3.list2',
        defaultMessage:
            'There can be multiple reasons that we can’t assign a user from the CSV file.  ',
    },
    manageUsersItem3list3: {
        id: 'manage.item3.list3',
        defaultMessage:
            'If you receive an error after you have uploaded a CSV file, you can download the error log, this will tell you the reason why it was not possible to link the user to the voice product. ',
    },
    manageUsersItem3list4: {
        id: 'manage.item3.list4',
        defaultMessage:
            'Once you’ve corrected the errors on the CSV, you can upload this again. Or, you can manually assign failed users using the dropdown. ',
    },
    msPlansHeader1: {
        id: 'plans.header1',
        defaultMessage: 'Microsoft plans',
    },
    msPlansItem1Title: {
        id: 'plans.items1.Title',
        defaultMessage: 'To configure Gamma Voice you will need to have available either:',
    },

    msPlansItem1list1: {
        id: 'plans.items1.list1',
        defaultMessage:
            '2 licenses for a Microsoft base plan which includes Microsoft Phone System.',
    },
    msPlansItem1list2: {
        id: 'plans.items1.list2',
        defaultMessage:
            '2 licenses for a Microsoft base plan and 2 licences for Microsoft Phone System or Business Voice.',
    },
    msPlansItem1list3: {
        id: 'plans.items1.list3',
        defaultMessage: 'Or',
    },

    msPlansExtraLicensesTitle: {
        id: 'plans.ExtraLicenses.Title',
        defaultMessage: 'Plans ',
    },
    msPlansExtraLicensesTitle2: {
        id: 'plans.ExtraLicenses.Title',
        defaultMessage: 'which require ',
    },
    msPlansExtraLicensesTitle3: {
        id: 'plans.ExtraLicenses.Title',
        defaultMessage: 'extra Phone System Licences',
    },
    msPlansExtraLicensesItem1: {
        id: 'plans.ExtraLicenses.item1',
        defaultMessage: 'Office 365 E1',
    },

    msPlansExtraLicensesItem2: {
        id: 'plans.ExtraLicenses.item2',
        defaultMessage: 'Office 365 E3',
    },

    msPlansExtraLicensesItem3: {
        id: 'plans.ExtraLicenses.item3',
        defaultMessage: 'Office 365 G3 GCC',
    },

    msPlansExtraLicensesItem4: {
        id: 'plans.ExtraLicenses.item4',
        defaultMessage: 'Office 365 E3 developer',
    },

    msPlansExtraLicensesItem5: {
        id: 'plans.ExtraLicenses.item5',
        defaultMessage: 'Microsoft 365 E3',
    },
    msPlansExtraLicensesItem6: {
        id: 'plans.ExtraLicenses.item6',
        defaultMessage: 'Microsoft 365 business basic',
    },

    msPlansExtraLicensesItem7: {
        id: 'plans.ExtraLicenses.item7',
        defaultMessage: 'Microsoft 365 business standard',
    },

    msPlansExtraLicensesItem8: {
        id: 'plans.ExtraLicenses.item8',
        defaultMessage: 'Microsoft 365 business standard - prepaid legacy',
    },

    msPlansExtraLicensesItem9: {
        id: 'plans.ExtraLicenses.item9',
        defaultMessage: 'Microsoft 365 business premium',
    },

    msPlansExtraLicensesItem10: {
        id: 'plans.ExtraLicenses.item10',
        defaultMessage: 'Microsoft 365 A1',
    },

    msPlansExtraLicensesItem11: {
        id: 'plans.ExtraLicenses.item11',
        defaultMessage: 'Microsoft 365 G3 GCC',
    },

    msPlansIncludeLicensesTitle: {
        id: 'plans.IncludeLicenses.Title',
        defaultMessage: 'Plans ',
    },
    msPlansIncludeLicensesTitle2: {
        id: 'plans.IncludeLicenses.Title',
        defaultMessage: 'which include ',
    },
    msPlansIncludeLicensesTitle3: {
        id: 'plans.IncludeLicenses.Title',
        defaultMessage: 'Phone System Licences',
    },
    msPlansIncludeLicensesItem1: {
        id: 'plans.ExtraLicenses.item1',
        defaultMessage: 'Office 365 E5',
    },

    msPlansIncludeLicensesItem2: {
        id: 'plans.ExtraLicenses.item2',
        defaultMessage: 'Office 365 A5 for students',
    },

    msPlansIncludeLicensesItem3: {
        id: 'plans.ExtraLicenses.item3',
        defaultMessage: 'Office 365 A5 for faculty',
    },

    msPlansIncludeLicensesItem4: {
        id: 'plans.ExtraLicenses.item4',
        defaultMessage: 'Office 365 E5 without audio conferencing',
    },

    msPlansIncludeLicensesItem5: {
        id: 'plans.ExtraLicenses.item5',
        defaultMessage: 'Microsoft 365 A5 for faculty',
    },
    msPlansIncludeLicensesItem6: {
        id: 'plans.ExtraLicenses.item6',
        defaultMessage: 'Microsoft 365 A5 for students',
    },
});

export default messages as typeof messages;
