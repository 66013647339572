import AccordionWithLink from '../AccordionWithLink';
import { Layout, Text } from '@fluentui/react-northstar';
import React from 'react';
import MicrosoftPlans from './MicrosoftPlans';

const MicrosoftPlan = () => {
    return (
        <AccordionWithLink
            panels={[
                {
                    title: 'A qualifying Microsoft plan*',
                    content: (
                        <Layout
                            className="content-wrapper"
                            key="ms-plan"
                            style={{ width: '635px' }}
                        >
                            <Layout className="ui-text-wrapper">
                                <MicrosoftPlans />
                            </Layout>
                        </Layout>
                    ),
                },
            ]}
        />
    );
};

export default MicrosoftPlan;
