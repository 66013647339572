import React from 'react';
import { Layout } from '@fluentui/react-northstar';

type AccordionLinkProps = {
    message?: string;
    expandedText: string;
};

const AccordionLink = (props: AccordionLinkProps) => {
    return (
        <Layout key={0} className="accordion-title-content">
            <span className="title-text">{props.message}</span>
            <a href="#" className="ui-accordion__link styled-link">
                {props.expandedText}
            </a>
        </Layout>
    );
};

export default AccordionLink;
