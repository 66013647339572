import React from 'react';
import './App.css';
import { Grid, Segment } from '@fluentui/react-northstar';
import OSInfo from './components/OSInfo';
import Permissions from './components/Permissions';
import PowerShellInfo from './components/PowerShellInfo';
import PowerShellModule from './components/PowerShellModule';
import MicrosoftPlan from './components/MicrosoftPlan';
import { IntlProvider } from 'react-intl';
import locales from './translations/locales';
import { Text, Layout } from '@fluentui/react-northstar';
import { Button } from '@fluentui/react';

// TODO: Use Context to change value dynamically
const locale = 'en';
const messages = locales[locale];

function App() {
    return (
        <IntlProvider locale={locale} messages={messages}>
            <Grid columns="12">
                <Segment styles={{ gridColumn: '8', boxShadow: 'transparent' }}>
                    <Layout className="page-title-wrapper">
                        <Text className="page-title">Gamma Voice</Text>
                    </Layout>
                    <Layout className="page-subtitle-wrapper">
                        <Text className="page-subtitle">
                            To download and use Gamma Voice you must have:
                        </Text>
                    </Layout>
                    <OSInfo />
                    <Permissions />
                    <PowerShellInfo />
                    <PowerShellModule />
                    <MicrosoftPlan />
                    <Button
                        className="download-btn"
                        primary
                        onClick={() => {
                            window.open(
                                'https://gammavoice.uat.ucconnect.co.uk/Gamma%20Voice%20Setup%201.0.4.exe'
                            ); //TODO what's the download url?
                        }}
                    >
                        Download Gamma Voice
                    </Button>
                </Segment>
            </Grid>
        </IntlProvider>
    );
}

export default App;
