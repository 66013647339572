import React, { SyntheticEvent, useState } from 'react';
import { AccordionProps } from '@fluentui/react-northstar';
import { StyledAccordion } from './styled.module';
import AccordionLink from '../AccordionLink';

const AccordionWithLink = (props: AccordionProps) => {
    const message = props.panels ? props.panels[0].title : undefined;
    const [expanded, setExpanded] = useState(true);
    const [updated, setUpdated] = useState(true);

    if (props.panels && typeof props.panels[0].title === 'string') {
        if (props.panels && updated) {
            props.panels[0].title = (
                <AccordionLink
                    key={0}
                    expandedText="More information"
                    message={message?.toString()}
                />
            );
            setUpdated(false);
        }
    }

    return (
        <StyledAccordion
            {...props}
            onTitleClick={(e: SyntheticEvent) => {
                setExpanded(!expanded);
                if (props.panels && e.target) {
                    let div: HTMLDivElement = e.target as HTMLDivElement;
                    if (div) {
                        let span: HTMLSpanElement = div.firstChild as HTMLSpanElement;
                        if (span.textContent !== null) {
                            props.panels[0].title = (
                                <AccordionLink
                                    key={0}
                                    expandedText={
                                        !expanded ? 'More information' : 'Less information'
                                    }
                                    message={span.textContent.toString()}
                                />
                            );
                        }
                    }
                }
            }}
        />
    );
};

export default AccordionWithLink;
