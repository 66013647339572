import { Accordion, AccordionProps } from '@fluentui/react-northstar';
import styled from 'styled-components';

export const StyledAccordion = styled(Accordion)<AccordionProps>`
    margin-bottom: 16px;
    display: block !important;

    .ui-accordion__title {
        width: 980px;
        height: 66px;
        background: #ecedf8;
        font-family: Mulish, sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 18px;
        line-height: 28px;
        text-align: left;
    }

    .ui-accordion__title__content-wrapper {
        padding-left: 12px;
        padding-right: 12px;
        padding-top: 11px;
    }

    a.styled-link {
        text-decoration: underline dotted;
        text-underline-offset: 4px;
    }

    a.ui-accordion__link {
        text-align: right;
        width: 128px;
        height: 26px;
        padding: 1px;

        font-family: Lato, sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 26px;

        color: #1b1f3b;
    }

    .accordion-title-content {
        display: flex;
        width: 955px;
    }

    .title-text {
        text-align: right;
        align-content: end;
        margin-right: auto;
    }

    .ui-accordion__title__content-wrapper > div:first-child {
        display: none;
    }

    .ui-accordion__content {
        width: 980px;
        background: #f7f8fd;
        padding-left: 12px;
        padding-top: 18px;
        padding-bottom: 24px;
    }

    .content-wrapper {
        width: 647px;
        display: block;
        font-family: Lato, sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
        color: #1b1f3b;
    }

    .content-wrapper div a {
        text-decoration: underline;
    }

    .content-wrapper div .ui-text-wrapper .ui-text a {
        text-decoration: underline;
    }

    .content-wrapper .ui-text-wrapper {
        margin-top: 8px;
        margin-bottom: 8px;
        height: auto;
    }

    .content-wrapper .ui-text-wrapper .ui-text-wrapper-item {
        margin-top: 4px;
        margin-bottom: 4px;
        height: auto;
    }

    .content-wrapper ul {
        margin: 0;
        margin-top: 12px;
        margin-bottom: 12px;
    }

    .content-wrapper li {
        margin: 0;
    }
`;
