const textItemColumnBorderR = {
    marginBottom: 12,
    marginLeft: 12,
    paddingRight: 8,
    borderRight: '1px solid #E0E0E0',
};

const textItemColumn = {
    marginBottom: 12,
    marginLeft: 12,
};

const textItem = {
    marginLeft: 12,
};

const textTitle = {
    marginBottom: 8,
};

const circleAndText = {
    marginBottom: 8,
};

const styles = {
    textItemColumn: textItemColumn,
    circleAndText: circleAndText,
    textItem: textItem,
    textTitle: textTitle,
    textItemColumnBorderR: textItemColumnBorderR,
};

export default styles;
