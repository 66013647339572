import React from 'react';
import AccordionWithLink from '../AccordionWithLink';
import { Layout } from '@fluentui/react-northstar';

const OSInfo = () => {
    return (
        <AccordionWithLink
            panels={[
                {
                    title: 'Windows 10 operating system',
                    content: (
                        <Layout
                            className="content-wrapper"
                            key="os-info"
                            style={{ width: '635px' }}
                        >
                            <span>To check which operating system you currently have, visit </span>
                            <a
                                style={{ textDecoration: 'underline' }}
                                href="https://docs.microsoft.com/en-us/microsoftteams/teams-powershell-overview"
                            >
                                Microsoft’s operating system help guide
                            </a>
                        </Layout>
                    ),
                },
            ]}
        />
    );
};

export default OSInfo;
